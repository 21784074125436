import { ThemeProvider } from "@emotion/react";
import GlobalTheme from "../theme/GlobalTheme";
import { Card, Divider, Grid, Link, List, ListItem, ListItemText, responsiveFontSizes, Skeleton, Snackbar, SnackbarContent, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import DiscountIcon from '@mui/icons-material/Discount';
import InstagramIcon from '@mui/icons-material/Instagram';
import axios from "axios";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";

import { motion } from "framer-motion";
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, timelineItemClasses } from "@mui/lab";
import ParallaxParticles from "../components/ParallaxParticles";

export default function Quote() {
    const { id, event } = useParams();
    const [loading, setLoading] = useState(true);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [location, setLocation] = useState("");
    const [social, setSocial] = useState("");
    const [weddingPackage, setWeddingPackage] = useState("");
    const [addons, setAddons] = useState("");
    const [discount, setDiscount] = useState("");
    const [total, setTotal] = useState("");
    const [notes, setNotes] = useState("");
    const [expiry, setExpiry] = useState("");

    const [showAlert, setShowAlert] = useState(true);
    const [alertMessage, setAlertMessage] = useState("Loading...");

    const bigDevice = useMediaQuery(GlobalTheme.breakpoints.up('md'));

    const targetRef = useRef<HTMLDivElement | null>(null);

    const scrollToDiv = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        axios.post("/signature-moments-data-ingest", {
            action: 'GET_QUOTE_DETAILS',
            quote_no: id,
            email: "1",
            password: "1"
        }).then(response => {
            if (response.status === 200) {
                setName(response.data[1]);
                setPhone(response.data[2]);
                setEmail(response.data[3]);
                setDate(response.data[4]);
                setLocation(response.data[5]);
                setSocial(response.data[6]);
                setWeddingPackage(response.data[7]);
                setAddons(response.data[8]);
                setDiscount(response.data[9]);
                setTotal(response.data[10]);
                setNotes(response.data[11]);
                setExpiry(response.data[12]);
                setLoading(false);
                setShowAlert(false);
                setAlertMessage("");
                scrollToDiv();
            } else {
                setAlertMessage("Failed! Please try again.");
                setShowAlert(true);
            }
        }).catch(error => {
            setAlertMessage("Failed! Please try again.");
            setShowAlert(true);
        })
    }, []);

    return (
        <ThemeProvider theme={responsiveFontSizes(GlobalTheme)}>
            <Grid
                container
                component="main"
                textAlign={"center"}
                sx={{
                    backgroundColor: "primary.main",
                    minHeight: "100vh"
                }}
            >
                <Grid item xs={12} md={6} p={0} alignContent={"center"}>
                    <ParallaxParticles/>
                </Grid>
                {
                    loading ? bigDevice ? <Grid item xs={12} md={6} p={2} alignContent={"center"} sx={{ backgroundColor: "primary.light" }}>
                        <Grid container justifyContent={"center"} px={3}>
                            <Grid item xs={6}>
                                <Skeleton height={200} />
                            </Grid>
                            <Grid item xs={10}>
                                <Skeleton height={150} />
                            </Grid>
                            <Grid item xs={8}>
                                <Skeleton height={100} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={6} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                        </Grid>
                    </Grid> : null : <Grid ref={targetRef} item xs={12} md={6} p={2} alignContent={"center"} sx={{ backgroundColor: "primary.light" }}>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                                transition: {
                                    duration: 1
                                }
                            }}
                            viewport={{ once: true }}
                        >
                            <Grid container>
                                <Grid item xs={12} pb={1} pt={2}>
                                    <Typography variant="h2" fontWeight={"700"}>Quotation</Typography>
                                </Grid>
                                <Grid item xs={12} py={1} px={{sm: 4, md: 8, lg: 16}}>
                                    <Divider variant="middle"/>
                                </Grid>
                                <Grid item xs={12} pb={1}>
                                    <Typography variant="h3" fontWeight={"700"} p={1}>{name}</Typography>
                                </Grid>
                                <Grid item xs={12} pb={1}>
                                    <Typography>{notes}</Typography>
                                </Grid>
                                <Grid item xs={12} pb={4}>
                                    <Typography variant="h4" fontWeight={"700"} p={1}>{weddingPackage}</Typography>
                                    {weddingPackage === "Luminous Moments" ? <Typography variant="body2">1 Candid Photographer, 1 Candid Videographer<br />1 Traditional Photographer, 1 Traditional Videographer</Typography> : null}
                                    {weddingPackage === "Radiant Moments" ? <Typography variant="body2">2 Candid Photographers, 2 Candid Videographers<br />2 Traditional Photographers, 2 Traditional Videographers<br />Pre or Post Wedding Session, Drone<br />Classic 25 Sheet Album</Typography> : null}
                                    {weddingPackage === "Summit Moments" ? <Typography variant="body2">3 Candid Photographers, 3 Candid Videographers<br />2 Traditional Photographers, 2 Traditional Videographers<br />Pre and Post Wedding Session, Drone<br />Premium 35 Sheet Album, AI Photo Sharing<br />LED Wall 12x8</Typography> : null}
                                    {addons !== null && addons !== "" ? <Typography variant="h6" fontWeight={"700"} pt={2}>Extra Add Ons</Typography> : null}
                                    {addons !== null && addons !== "" ? <Typography variant="body2">{addons}</Typography> : null}
                                </Grid>
                                <Grid item xs={12} md={6} pb={3} pt={1}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 100 }}
                                        whileInView={{
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }
                                        }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <EventIcon />
                                        <Typography variant="body1">{date}</Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} md={6} pb={3} pt={1}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 100 }}
                                        whileInView={{
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }
                                        }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <PlaceIcon />
                                        <Typography>{location}</Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} md={6} pb={3} pt={1}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 100 }}
                                        whileInView={{
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }
                                        }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <PhoneIcon />
                                        <Typography>{phone}</Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} md={6} pb={3} pt={1}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 100 }}
                                        whileInView={{
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }
                                        }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <EmailIcon />
                                        <Typography>{email}</Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} md={6} pb={3} pt={1}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 100 }}
                                        whileInView={{
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }
                                        }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <DiscountIcon />
                                        <Typography sx={{ textDecorationLine: "line-through" }}>{discount}</Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} md={6} pb={3} pt={1}>
                                    <motion.div
                                        initial={{ opacity: 0, y: 100 }}
                                        whileInView={{
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }
                                        }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <CurrencyRupeeIcon />
                                        <Typography>{total}</Typography>
                                    </motion.div>
                                </Grid>
                                <Grid item xs={12} pb={3} alignItems="center">
                                    <motion.div
                                        initial={{ opacity: 0, y: 100 }}
                                        whileInView={{
                                            opacity: 1,
                                            y: 0,
                                            transition: {
                                                duration: 1
                                            }
                                        }}
                                        whileHover={{ scale: 1.1 }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <InstagramIcon />
                                        <Typography>{social}</Typography>
                                    </motion.div>
                                </Grid>
                            </Grid>
                        </motion.div>
                    </Grid>
                }
            </Grid>
            {
                !loading ?
                    <Grid container sx={{ backgroundColor: "black", pt: { sm: 1, md: 4, lg: 8 } }}>
                        <Grid item xs={12} lg={6} textAlign={'center'} sx={{ px: { xs: 2, md: 8, lg: 16 } }}>
                            <motion.div
                                initial={{
                                    y: 300,
                                }}
                                whileInView={{
                                    y: 0,
                                    transition: {
                                        type: "spring",
                                        bounce: 0.4,
                                        duration: 0.8
                                    }
                                }}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                viewport={{ once: true }}
                            >
                                <Card sx={{ mt: { xs: 4, md: 6, lg: 8, borderRadius: 15 } }}>
                                    <Typography variant={'h4'} pt={3} fontWeight={700}>Deliverables</Typography>
                                    <Timeline sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                        px: { sm: 2, md: 3, lg: 4 }
                                    }}>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>A set of 300 edited candid photos will be deliverd through an online link.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>All traditional photos will be deliverd through an online link.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>A candid video narrating the story of the event will be delivered through an online link.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>A traditional video covering the entire event will be delivered through an online link.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                            </TimelineSeparator>
                                            <TimelineContent>The delivery of the outputs will take at least 90 days.</TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Card>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} lg={6} textAlign={'center'} sx={{ px: { xs: 2, md: 8, lg: 16 } }}>
                            <motion.div
                                initial={{
                                    y: 300,
                                }}
                                whileInView={{
                                    y: 0,
                                    transition: {
                                        type: "spring",
                                        bounce: 0.4,
                                        duration: 0.8
                                    }
                                }}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                viewport={{ once: true }}
                            >
                                <Card sx={{ mt: { xs: 4, md: 6, lg: 8, borderRadius: 15 } }}>
                                    <Typography variant={'h4'} pt={3} fontWeight={700}>Payment Terms</Typography>
                                    <Timeline sx={{
                                        [`& .${timelineItemClasses.root}:before`]: {
                                            flex: 0,
                                            padding: 0,
                                        },
                                        px: { sm: 2, md: 3, lg: 4 }
                                    }}>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>60% of the package cost must be paid as a non-refundable booking advance.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>40% of the package cost must be paid on the event date.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>Post-processing work will begin only after full payment is completed, and this is non-negotiable.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>100% of the album package must be paid once the album design is finalized.</TimelineContent>
                                        </TimelineItem>
                                        <TimelineItem>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                            </TimelineSeparator>
                                            <TimelineContent>The team will handle travel and accommodation if the event location is within Tamil Nadu.</TimelineContent>
                                        </TimelineItem>
                                    </Timeline>
                                </Card>
                            </motion.div>
                        </Grid>
                    </Grid>
                    : null
            }
            {
                !loading ? <Footer /> : null
            }
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000}
                open={showAlert} onClose={() => setShowAlert(false)}>
                <SnackbarContent message={alertMessage} sx={{
                    borderRadius: 3,
                    color: 'primary.main',
                    justifyContent: 'center',
                    backgroundColor: 'primary.light',
                }} />
            </Snackbar>
        </ThemeProvider>
    )
}